import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

import './Service.scss';

const Service = ({ serviceList }) => {
  const { title, description, icon , more } = serviceList;
  const [showModal, setShowModal] = useState(false);

  const handleMoreClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <div className='col-lg-4 col-md-6 col-sm-6'>
      <div className="service-box">
        <div className="service-icon">
          <div className='icon-area'>
            <img src={icon} alt="icon" />
          </div>
        </div>
        <div className="service-text">
          <h3><Link to="/singleservice">{title}</Link></h3>
          <div dangerouslySetInnerHTML={{ __html: description }} style={{ fontSize: '14px', textAlign: 'justify' }} />
          <p className="more-link" onClick={handleMoreClick}>Read More</p>
        </div>
        <style jsx>{`
          div > ul > li {
            margin-bottom: 10px; /* Adjust the margin as needed */
          }
        `}</style>
      </div>

     
    </div>

     <Modal show={showModal} onHide={handleCloseModal} centered size="lg"  dialogClassName="custom-modal-dialog">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='myc'>
          <div dangerouslySetInnerHTML={{ __html: more }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      </>
  );
};

export default Service;
