import iconOne from '../../assets/servicePage/gallery/1.png';
import iconTwo from '../../assets/servicePage/gallery/2.png';
import iconThree from '../../assets/servicePage/gallery/3.png';
import iconFour from '../../assets/servicePage/gallery/4.png';
import iconFive from '../../assets/servicePage/gallery/5.png';
import iconSix from '../../assets/servicePage/gallery/6.png';
import iconSeven from '../../assets/servicePage/gallery/7.png';
import iconEight from '../../assets/servicePage/gallery/8.png';




const ServicesData = [
    {
        'icon': iconOne,
        'title': 'General Dentistry',
        'description': 'Comprehensive care for all your family including cleaning, X-ray & oral scans, tooth-colored fillings, root fillings, implants, crown & bridge, veneers, flexible dentures, wisdom tooth removal',
        "more": '<ul><li>Checkup & Cleaning: Oral endoscopy, digital X-ray, intraoral scan, scaling / deep cleaning</li><li>Restorations: Tooth-colored fillings, root canal therapy, crowns & bridges, traditional & flexible dentures</li><li>Preventive Care: Fluoride therapy, fissure sealants, night guards, sport mouth guards</li><li>Cosmetic Enhancements: Bonding, veneers, teeth whitening, diamond placement</li><li>Extractions: Tooth extractions, wisdom tooth removal</li><li>Pediatric Care: Routine check-ups, fluoride treatments, and sealants for our youngest patients</li><li>Emergency Dental Care: Immediate swift care for dental emergencies like toothaches, broken teeth, and infections</li><li>Oral Cancer Screenings: Identify early signs of cancer for prompt and effective treatment</li></ul>',
    },
    {
        'icon': iconTwo,
        'title': 'Implant Dentistry',
        'description': 'Implant placement, full arch implants (All on 4 / 6), same-day implants, Implant-Supported crown, bridge & dentures, Bone grafting, Sinus lift, ridge augmentation',
        "more":'<ul><li>Implant Placement: Single, multiple, and full arch solutions</li><li>Advanced Techniques: Bone grafting, sinus lift, ridge augmentation for optimal implant success</li><li>Restorative Options: Crowns, bridges, and dentures supported by implants</li></ul>'
    },
    {
        'icon': iconThree,
        'title': 'Cosmetic Dentistry',
        'description': 'Enhance your smile. We offer teeth whitening, crowns & bridges, veneers, diamond placement, smile makeover, clear aligners & braces, gum treatments, tooth-colored fillings',
         "more":'<ul><li>Teeth Whitening: Advanced LED light acceleration system</li><li>Cosmetic Solutions: Bonding, veneers, crowns (all ceramic / porcelain fused to metal / zirconia), bridges (traditional and implant-supported), diamond / jewel placement</li><li>Smile Makeover: Hollywood smile, orthodontics (clear aligners / traditional braces), tooth-colored fillings, replace amalgam fillings, onlays, inlays, facial aesthetics</li><li>Gum Treatments: Gum contouring, crown lengthening</li></ul>'
    },
    {
        'icon': iconFour,
        'title': 'Orthodontics',
        'description': 'Straighten your teeth with Invisible aligners / clear braces, traditional metal braces, jaw alignment surgeries',
        "more":'<ul><li>Aligners & Braces: Invisible aligners / clear braces, traditional metal braces</li><li>Early Treatments: Interceptive treatment, retainers, space maintainers</li><li>Surgical Solutions: Surgical orthodontics, jaw alignment surgery</li></ul>'
    },
    {
        'icon': iconFive,
        'title': 'Restorative Dentistry',
        'description': 'Restore your smile with our expert restorative/prosthodontic care. Crown & bridges, dental implants, root fillings, flexible dentures',
        "more":'<ul><li>Restorations: Crowns (all ceramic / porcelain fused to metal/zirconia), bridges (traditional and implant-supported), dentures (traditional / flexible / implant-supported), dental implants (single / multiple / full arch), root canal therapy, full mouth rehabilitation, onlays, inlays</li><li>Teeth, Bone & Jaw Health: Bone graft, sinus lift, ridge augmentation, TMJ / TMD treatment, night guards, sport mouth guards, cavity prevention (fluoride, sealants)</li></ul>'
    },
    {
        'icon': iconSix,
        'title': 'Teeth Whitening',
        'description': 'Brighten your smile with our professional whitening treatments. We offer In-Office Whitening with an advanced LED light acceleration system giving you a whiter, dazzling smile',
        "more":'<ul><li>Consultation and Assessment: Our dental professionals will evaluate your teeth and discuss your whitening goals to ensure you’re a good candidate for the procedure.</li><li>Preparation: We’ll clean your teeth to remove any plaque or debris that could affect the whitening process.</li><li>Application: A high-concentration whitening gel is applied to your teeth. The LED light is then used to activate the gel, breaking down stains and discoloration.</li><li>Results: You’ll notice a significant improvement in the whiteness of your teeth, often several shades lighter, after just one session.</li></ul>'
    },
    {
        'icon': iconSeven,
        'title': 'Cosmetic Treatments & Surgery',
        'description': 'Our cosmetic expert provides treatments for the whole body, including facial aesthetics including hair transplant, skin treatments, and body contouring',
        'more':'<ul><li>Hair & Skin: Hair transplant, beard transplant, eyebrow transplant, silicone implants, weight gain/reduction, liposuction, acne/scar treatments, chemical peels, dermal fillers, thread lifts, microdermabrasion, micropigmentation for lips / eyebrows / vitiligo, white hair removal</li><li>Aesthetic Procedures: Skin lightening, mesotherapy, micro-needling</li></ul>'
    },
    {
        'icon': iconEight,
        'title': 'Maxillofacial Surgery',
        'description': 'Expert care for facial and jaw surgery: Dimple creation, wart/wrinkle removal, nose/eyelid/lip/chin reshaping, tongue tie correction, wisdom tooth removal, jaw surgeries',
        'more':'<ul><li>Facial Cosmetics: Dimple creation, rhinoplasty (nose reshaping), blepharoplasty (eyelid correction), facelift, neck lift, brow lift, lip/chin augmentation, scar revision, wart / wrinkle removal</li><li>Mouth/Jaw Surgery: Wisdom teeth removal, tongue tie correction, jaw surgery, bone grafting, sinus lift, ridge augmentation, alveolar bone graft, canine exposure, Jaw joint (TMJ) management</li><li>Others: Facial trauma management, salivary gland treatment, facial reconstruction, biopsies, cyst/tumor management, sleep apnea surgery</li></ul>'
    }
]

export default ServicesData;