import React from 'react';
import './Gallery.scss';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import imgOne from '../../assets/gallery/1.png';
import imgTwo from '../../assets/gallery/2.png';
import imgThree from '../../assets/gallery/3.png';
import imgFour from '../../assets/gallery/4.png';


const Gallery = () => {
    return (
        <section className='gallery-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
            <div className="row">
                    <div className="col-lg-2 col-sm-2">
                        <SectionTitleUp title="" subTitle="OUR GALLERY" />

                    </div>
                   
                </div>
               
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <div className="gallery-img">
                            <img src={imgOne} alt="gallery" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="gallery-img">
                            <img src={imgTwo} alt="gallery" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="gallery-img">
                            <img src={imgThree} alt="gallery" />
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <div className="gallery-img">
                            <img src={imgFour} alt="gallery" />
                        </div>
                    </div>
                    
                </div>

                <div className="row">

                <div className="col-md-12 col-sm-12"> 
                <SectionTitleUp 
                    subTitle=""
                    title="We combine quality, comfort and technology for incredible results"
                    description="Dr. Hiran and our skilled team are unlocking the potential of cutting-edge techniques to reshape smiles and transform lives."
                />
                
                </div>


                </div>
            </div>
        </section>
    );
};

export default Gallery;