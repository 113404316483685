import React from 'react';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services2 = ({ displayLink }) => {
    return (
        <>
            <section className='symptoms-section section-common pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">

                <div className="container">


                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <h3>What is 6thLane Dentistry?</h3>\
                            <p className='black'>6thLane Dentistry is your trusted state of the art center for affordable dentistry providing high quality care under international standards, founded by Dr Hiran Thenuwara who is a dentist and also a musician, teamed up with clinicians of various specialties who studied, qualified and accredited in the UK, USA, Australia and Sri Lanka.
                                <br /><br />
                                We are Sri Lanka’s first sustainable health brand focused on green dentistry.
                                <br />
                                Location: 19, Colombo-Horana Road (Sunethradevi Road), Kohuwala, Nugegoda, Colombo, Sri Lanka.
                                <br /> <br />
                                <strong>Our Vision </strong>
                                <br />
                                Gain global recognition as a sustainable health brand which is providing high quality affordable reliable dentistry.
                                <br /> <br />
                                <strong>Our Mission  </strong>
                                <br />
                                Provide affordable high quality care & treatments under international standards with unique user experience.
                                <br /><br />
                                <strong>Our practice & core values</strong> <br /><br />

                                <ul>
                                    <li>High quality affordable treatments.</li>
                                    <li>State of the art technology, high class material and latest equipment.</li>
                                    <li>Skilled qualified accredited doctors.</li>
                                    <li>Safety, cleanliness, sterility and infection control protocols.</li>
                                    <li>Unique user experience, support and follow up care.</li>
                                    <li>Sustainability.</li>


                                </ul>

                            </p>

                        </div>

                    </div>


                </div>

                {displayLink === 1 ?

                    <div className="services-link text-center">
                        <Link to='/singleservice'>
                            View all services
                            <BsFillArrowRightCircleFill />
                        </Link>
                    </div>
                    : null}
              
            </section>


            <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">

                <div className='container'>

                    <div className="row">
                        <div className="col-lg-12 col-sm-12">
                            <h3>What we do</h3> <br />
                            <p className='black'>Our full range team will deliver value experience like no other.</p>

                        </div>
                    </div>

                    <div className="row">
                        {
                            ServicesData.map(singleService => <Service serviceList={singleService} />)
                        }
                    </div>
                </div>
            </section>


            <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000"> 
            <div className="container">
                    <div className="service-boxnew">
                        <div className="row">
                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>General</strong></h6> <br />
                                <ul>

                                    <li>Digital X-Rays </li>
                                    <li>Hollywood smile makeover </li>
                                    <li>All-on-4/6 </li>
                                    <li>  Diamond / jewel placement</li>
                                    <li>Porcelain veneers</li>
                                    <li>Bonding</li>
                                    <li>Gum lift / contouring</li>

                                </ul>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Implants</strong></h6> <br />
                                <ul>

                                    <li> Dental implants</li>
                                    <li>All-On-X</li>
                                    <li>Implant supported dentures</li>
                                    <li>Bone grafts</li>
                                    <li>Sinus lifting</li>
                                    <li>Ridge augmentation</li>

                                </ul>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Cosmetics</strong></h6> <br />
                                <ul>

                                    <li> Digital smile design</li>
                                    <li>Hollywood smile makeover</li>
                                    <li>All-on-4/6</li>
                                    <li>Diamond / jewel placement</li>
                                    <li>Porcelain veneers</li>
                                    <li>Bonding</li>
                                    <li>Gum lift / contouring</li>

                                </ul>
                            </div>


                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Orthodontics</strong></h6> <br />
                                <ul>
                                    <li>  Invisible / clear aligners</li>
                                    <li> Conventional braces</li>
                                    <li> Surgically Facilitated Orthodontic Therapy (SFOT)</li>


                                </ul>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Root canal / crown & bridge</strong></h6> <br />
                                <ul>
                                    <li>  Endodontics / root canals</li>
                                    <li>Crowns</li>
                                    <li>Bridges</li>


                                </ul>
                            </div>


                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Teeth whitening</strong></h6> <br />
                                <ul>
                                    <li>  Blue LED acceleration therapy</li>


                                </ul>
                            </div>

                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Oral & maxillofacial surgery</strong></h6> <br />
                                <ul>
                                    <li>Dimple creation</li>
                                    <li>Lip / chin augmentation</li>
                                    <li>Frenectomy / Tongue tie</li>
                                    <li>Corrective jaw surgery</li>
                                    <li>Alveolar bone graft</li>
                                    <li>Oro facial trauma / pathology / lumps / tumors</li>
                                    <li>TMJ / TMD / salivary gland treatment</li>
                                    <li>Canine exposure</li>
                                    <li>PRP</li>
                                    <li>Scar revision</li>
                                    <li>Wrinkle removal</li>
                                    <li>Nose correction</li>
                                    <li>Cleft lip / palate</li>
                                    <li>Biopsy / Oral cancer screening</li>



                                </ul>
                            </div>


                            <div className="col-lg-4 col-sm-4">
                                <h6><strong>Health & wellness</strong></h6> <br />
                                <ul>
                                    <li>  Nutrition & exercise</li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Services2;