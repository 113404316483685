import React, { useState } from 'react';
import './Emergency.scss';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import EmergencyImg from '../../assets/team.png';
import { Modal, Button } from 'react-bootstrap';

const Emergency = () => {
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const modalContent = `
        <div class="team-member">
            <h4>Dr. Hiran Thenuwara</h4>
            <p><strong>Founder & Chief Dentist</strong></p>
            <p>Dr. Hiran Thenuwara is a renowned dentist with over 15 years of experience in the field. As the founder of 6th Lane Dentistry, Dr. Hiran combines expertise with a passion for patient care and environmental sustainability. Dr. Hiran specializes in comprehensive dental care, ensuring every patient receives personalized and high-quality treatment.</p>
            <p><strong>Specializations:</strong> General dentistry, cosmetic dentistry, implant dentistry</p>
            <p><strong>Education:</strong> Royal College - Colombo, University of Peradeniya - Sri Lanka, additional training in implant dentistry from AAID Maxicourse (American Academy of Implant Dentistry)</p>
        </div>
        <div class="team-member">
            <h4>Dr. Sachithra Dissanayake</h4>
            <p><strong>Orthodontist</strong></p>
            <p>Dr. Sachithra Dissanayake is a board-certified orthodontist with a keen eye for detail and a commitment to creating beautiful, functional smiles. With extensive experience in traditional braces, Dr. Sachithra provides customized orthodontic solutions for patients of all ages.</p>
        </div>
        <div class="team-member">
            <h4>Dr. Chanaka Dissanayake</h4>
            <p><strong>Prosthodontist</strong></p>
            <p>Dr. Chanaka Dissanayake is a board-certified prosthodontist dedicated to restoring smiles with precision and care. Specializing in restorative dentistry, Dr. Chanaka offers a range of services from implants, crowns, and bridges to full mouth rehabilitation.</p>
        </div>
        <div class="team-member">
            <h4>Dr. Damith Fonseka</h4>
            <p><strong>Registrar in Oral & Maxillofacial Surgery</strong></p>
            <p>Dr. Damith Fonseka ensures optimal outcomes for patients requiring oral and maxillofacial surgical interventions with his advanced training and compassionate approach. He collaborates with board-certified consultants when needed, prioritizing the best care for every patient.</p>
        </div>
        <div class="team-member">
            <h4>Dr. Kasun Hasthisinghaarachchi</h4>
            <p><strong>Cosmetic Expert</strong></p>
            <p>Dr. Kasun brings a wealth of experience in cosmetic treatments for the whole body, including hair transplants, skin treatments, and body contouring.</p>
        </div>
    `;

    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitleUp 
                                subTitle="OUR TEAM" 
                                title="Welcome to 6th Lane Dentistry, where exceptional dental care meets a personal touch" 
                                description="Led by Dr. Hiran Thenuwara, a professional musician and the founder of 6th Lane Dentistry, with expertise in all aspects of dentistry, he ensures personalized, top-quality care. Our dedicated team includes UK-accredited, board-certified prosthodontists, orthodontists, and maxillofacial and cosmetic experts."
                            />
                            <Button variant="primary" onClick={handleShow}>
                                Read More
                            </Button>
                            

                            <Modal show={showModal} onHide={handleClose} size="lg" dialogClassName="custom-modal-dialog">
                                <Modal.Header closeButton>
                                    <Modal.Title>Our Team</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className='myc'>
                                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Emergency;
