import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import Gallery from '../sections/Gallery/Gallery';
import ServiceBanner from '../sections/ServiceBanner/ServiceBanner';
import Services2 from '../sections/Services/Services2';
import Appointment from '../sections/Appointment/Appointment';

const ServicesCom = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      
    return (
        <>
            <Navbar />
            <ServiceBanner />
            <Services2 displayLink={2} />
             <Gallery /> 
            <Appointment />
            <Footer />
        </>
    );
};

export default ServicesCom;