import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import priorityImg from '../../assets/about/priority.png';
import './Priority.scss';

const Priority = () => {
    return (
        <section className='priority-section emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-img">
                            <img src={priorityImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="priority-text">
                            <SectionTitleUp 
                                subTitle="YOUR SMILE STARTS HERE" 
                                title="We want you to feel amazing"
                                description="Combining the best new technology with a great design, we've created a great experience that is focusing on you and your comfort."
                            />

                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Priority;