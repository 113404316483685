import React from 'react';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';

const ContactForm = () => {
    return (
        <form>
            <div className="row">
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="email" class="form-control" placeholder="Enter your name..." />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>E-mail Address</label>
                        <input type="email" class="form-control" placeholder="Enter email address..." />
                    </div>
                </div>

                <div className="col-lg-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Messages</label>
                        <textarea class="form-control" placeholder='Enter your messages...' rows="3"></textarea>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <img src={icon} alt="icon" />
                        </div>


                        <div className='call-text'>
                            <p>APPOINTMENTS</p>
                            <h6><a href="tel:0094777612834">0094 77 7612 834</a></h6>
                        </div>
                       
                    </div>

                   
                </div>
                <div className="col-lg-6">
                    

                    <div className="appointment-call">
                        <div className='icon'>
                            <img src={icon} alt="icon" />
                        </div>


                        
                        <div className='call-text'>
                            <p>EMERGENCY</p>
                            <h6><a href="tel:0094776663100">0094 77 666 3100</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;