import React from 'react';
import SectionTitleDown from '../../components/SectionTitle/SectionTitleDown';
import Navbar from '../../components/Navbar/Navbar';
import './Contactus.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../sections/Footer/Footer';

const Contactus = () => {
    return (
        <>
            <section className='section-bg section-common contact-section'>
                <Navbar />
                <SectionTitleDown 
                    title="Contact Us"
                    description="For inquiries or appointments, please leave your contact details. Our care coordinator will reach out to you soon.."
                />
            </section>
            <section className='contact-form-area' data-aos="fade-up" data-aos-duration="2000">
                <ContactForm />
            </section>
            <Footer />
        </>
    );
};

export default Contactus;