import React from 'react';
import './Team.scss';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import imgOne from '../../assets/about/team/1.png';
import imgTwo from '../../assets/about/team/2.png';
import imgThree from '../../assets/about/team/3.png';
import imgFour from '../../assets/about/team/4.png';

const Team = () => {

    const teams = [
        {
            'img': imgOne,
            'name': 'Dr Hiran Thenuwara'
        },
        {
            'img': imgTwo,
            'name': 'Dr Chanaka Dissanayake'
        },
        {
            'img': imgThree,
            'name': 'Dr Sachithra Dissanayake'
        },
       
    ]


    return (
        <section className='team-section pt-100' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitleUp 
                            subTitle="MEET OUR TEAM"
                            title="Get to know our full range team"
                        />
                    </div>

                    <div className="col-lg-5">
                        <p className='pt-5 cblack'>From experienced general dentists to UK accredited board certified prosthodontists, orthodontists, maxillofacial surgeons and wellness experts; we are building a highly skilled pro team to deliver top class services to you.</p>
                    </div>
                </div>

                <div className="row">
                    {
                        teams.map (team => 
                            <div className="col-lg-3 col-sm-6">
                                <div className="team-card">
                                    <div className="team-img">
                                        <img src={team.img} alt="" />
                                    </div>
                                    <h3>{team.name}</h3>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Team;