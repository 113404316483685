import React from 'react';
import SectionTitleUp from '../../components/SectionTitle/SectionTitleUp';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";


const Services = ({displayLink}) => {
    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitleUp title="Dentistry  |  Cosmetics" subTitle="OUR SERVICES"/>
                       
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>Premium is our standard. Let’s transform your smile !.</p>
                    </div>
                </div>

                <div className="row">
                    {
                        ServicesData.map(singleService => <Service serviceList={singleService}/>)
                    }
                </div>
            </div>
            {displayLink === 1 ?

            <div className="services-link text-center">
                <Link to='/singleservice'>
                    View all services
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div>
            : null}
        </section>
    );
};

export default Services;